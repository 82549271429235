import React, {Suspense, useState} from "react";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import TagManager from "react-gtm-module";
import i18n from "i18next";
// import * as Sentry from "@sentry/react";
import {Trans} from "react-i18next";
import {HelmetProvider} from "react-helmet-async";

import ErrorBoundary from "../../components/common/error/ErrorBoundary";
import Loading from "../../components/common/loading/loading";
import {baseUrl, checkParams, checkUrlLocale, lazyWithRetry, pageView} from "../../components/utils/functions";
import {availableLanguages} from "../../components/utils/variables";


import CookieConsent from "react-cookie-consent";


// const NavbarFront = lazyWithRetry(() => import("../../components/common/headtail/Navbars/NavbarFront"));
// const NavbarSub = lazyWithRetry(() => import("../../components/common/headtail/Navbars/NavbarSub"));
const NavbarFooter = lazyWithRetry(() => import("../../components/common/headtail/Navbars/navbarFooter"));
const NavbarNew = lazyWithRetry(() => import("../../components/common/headtail/Navbars/navbarNew"));
const FooterPublic = lazyWithRetry(() => import("../../components/common/headtail/Footers/FooterPublic"));

// const LandingPage = lazyWithRetry(() => import("../LandingPage/LandingPage"));
const NewLandingPage = lazyWithRetry(() => import("../LandingPage/newLandingPage"));
const PlatformPage = lazyWithRetry(() => import("../platformPage/platformPage"));
const SolutionsPage = lazyWithRetry(() => import("../solutionsPage/solutionsPage"));
const FlightPlan = lazyWithRetry(() => import("../FlightPlan/FlightPlan"));
const PassengerInformation = lazyWithRetry(() => import("../PassengerInfo/PassengerInformation"));
const Payment = lazyWithRetry(() => import("../Payment/Payment"));
const SingleClinic = lazyWithRetry(() => import("../SingleClinic/SingleClinic"));
const CountryRegulation = lazyWithRetry(() => import("../CountryRegulation/CountryRegulation"));
const RegulationCountries = lazyWithRetry(() => import("../CountryRegulation/RegulationCountries"));
const AllClinics = lazyWithRetry(() => import("../AllClinics/AllClinics"));
const RegionsList = lazyWithRetry(() => import("../AllClinics/RegionsList"));
const CountriesList = lazyWithRetry(() => import("../AllClinics/CountriesList"));
const Applications = lazyWithRetry(() => import("../Applications/Applications"));
const ClinicsNearMeOrg = lazyWithRetry(() => import("../HealthOrganization/ClinicsNearMeOrg"));
const ClinicsNearMeCountry = lazyWithRetry(() => import("../HealthOrganization/ClinicsNearMeCountry"));
const ContactForm = lazyWithRetry(() => import("../ContactForm/ContactForm"));

const PrivacyPolicy = lazyWithRetry(() => import("../legal/PrivacyPolicy"));
const LegalNotice = lazyWithRetry(() => import("../legal/LegalNotice"));
const CancellationPolicy = lazyWithRetry(() => import("../legal/CancellationPolicy"));
const CookiesPolicy = lazyWithRetry(() => import("../legal/CookiesPolicy"));
const TermsOfUse = lazyWithRetry(() => import("../legal/TermsOfUse"));

const NotFoundPage = lazyWithRetry(() => import("../../components/common/error/NotFoundPage"));  // 404


if (process.env.NODE_ENV === "production") {
  const tagManagerArgs = {
    gtmId: "GTM-M483B2B",
  };
  TagManager.initialize(tagManagerArgs);
}

// if there is an internet loading problem this part just force reloading the page
// See https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
window.addEventListener("error", e => {
  if ((/Loading chunk [\d]+ failed/.test(e.message)) || (/Loading.*[cC]hunk.*failed/.test(e.message)) || (/Network Error/.test(e.message)) || (/timeout of 0ms exceeded/.test(e.message))) {
    window.location.reload();
  }
});


function App() {

  const [lang, setLang] = useState();
  i18n.on("languageChanged", (lng) => {
    setLang(i18n.language);
  });

  checkUrlLocale();
  checkParams();
  if (process.env.NODE_ENV === "production") pageView();

  return (
    <HelmetProvider>
      <div className="App">
        <Router basename={baseUrl()}>
          <ErrorBoundary>
            <Suspense fallback={<Loading/>}>

              <Switch>

                <Route exact path="/">
                  <NavbarNew hideSub/>
                  <NewLandingPage/>
                  <NavbarFooter/>
                  <FooterPublic/>
                </Route>

                <Route exact path="/platform">
                  <NavbarNew hideSub/>
                  <PlatformPage/>
                  <NavbarFooter/>
                  <FooterPublic/>
                </Route>

                <Route exact path="/solutions">
                  <NavbarNew hideSub/>
                  <SolutionsPage/>
                  <NavbarFooter/>
                  <FooterPublic/>
                </Route>

                <Route
                  path="/flightplan"
                  render={props => (
                    <>
                      <NavbarNew fixedBar/>
                      <FlightPlan {...props}/>
                      <FooterPublic/>
                    </>
                  )}/>

                <Route
                  path="/passengerinformation"
                  render={props => (
                    <>
                      <NavbarNew fixedBar/>
                      <PassengerInformation {...props}/>
                      <FooterPublic/>
                    </>
                  )}/>

                <Route path="/cancellationpolicy">
                  <Redirect to="/cancellation-policy"/>
                </Route>

                <Route path="/faq">
                  <Redirect to="/"/>
                </Route>

                <Route path="/contact-us">
                  <NavbarNew fixedBar/>
                  <ContactForm/>
                  <FooterPublic/>
                </Route>

                <Route path="/cancellation-policy">
                  <NavbarNew fixedBar/>
                  <CancellationPolicy/>
                  <FooterPublic/>
                </Route>

                <Route path="/cookiespolicy">
                  <Redirect to="/cookies-policy"/>
                </Route>

                <Route path="/cookies-policy">
                  <NavbarNew fixedBar/>
                  <CookiesPolicy/>
                  <FooterPublic/>
                </Route>

                <Route path="/privacypolicy">
                  <Redirect to="/privacy-policy"/>
                </Route>

                <Route path="/privacy-policy">
                  <NavbarNew fixedBar/>
                  <PrivacyPolicy/>
                  <FooterPublic/>
                </Route>

                <Route path="/legalnotice">
                  <Redirect to="/legal-notice"/>
                </Route>

                <Route path="/legal-notice">
                  <NavbarNew fixedBar/>
                  <LegalNotice/>
                  <FooterPublic/>
                </Route>

                <Route path="/(termofuse|termsofuse)">
                  <Redirect to="/terms-of-use"/>
                </Route>

                <Route path="/terms-of-use">
                  <NavbarNew fixedBar/>
                  <TermsOfUse/>
                  <FooterPublic/>
                </Route>

                <Route path="/(application|applications|downloads)">
                  <Redirect to="/download"/>
                </Route>

                <Route path="/download">
                  <NavbarNew fixedBar/>
                  <Applications/>
                  <FooterPublic/>
                </Route>

                <Route
                  path="/clinics/:countryName/:regionName/:testName" exact={true}
                  render={props => (
                    <>
                      <NavbarNew fixedBar/>
                      <AllClinics {...props}/>
                      <FooterPublic/>
                    </>
                  )}>
                </Route>
                <Route
                  path="/clinics/:countryName/:regionName" exact={true}
                  render={props => (
                    <>
                      <NavbarNew fixedBar/>
                      <AllClinics {...props}/>
                      <FooterPublic/>
                    </>
                  )}>
                </Route>
                <Route
                  path="/clinics/:countryName" exact={true}
                  render={props => (
                    <>
                      <NavbarNew fixedBar/>
                      <RegionsList {...props}/>
                      <FooterPublic/>
                    </>
                  )}>
                </Route>
                <Route path="/clinics" exact={true}>
                  <NavbarNew fixedBar/>
                  <CountriesList/>
                  <FooterPublic/>
                </Route>

                <Route path="/clinic" exact={true}>
                  <Redirect push to="/"/>
                </Route>

                <Route
                  path="/clinic/:clinicName" exact={true}
                  render={props => (
                    <>
                      <NavbarNew fixedBar/>
                      <SingleClinic {...props}/>
                      <FooterPublic/>
                    </>
                  )}>
                </Route>

                <Route
                  path="/clinics-near-me" exact={true}
                  render={props => (
                    <>
                      <NavbarNew fixedBar/>
                      <ClinicsNearMeCountry {...props}/>
                      <FooterPublic/>
                    </>
                  )}>
                </Route>

                <Route
                  path="/clinics-near-me/:healthOrgSlug" exact={true}
                  render={props => (
                    <>
                      <NavbarNew fixedBar/>
                      <ClinicsNearMeOrg {...props}/>
                      <FooterPublic/>
                    </>
                  )}>
                </Route>

                <Route path="/travel-restrictions-and-travel-bans" exact={true}>
                  <NavbarNew fixedBar/>
                  <RegulationCountries/>
                  <FooterPublic/>
                </Route>
                <Route
                  path="/travel-restrictions-and-travel-bans/:countryName"
                  exact={true}
                  render={props =>
                    <>
                      <NavbarNew fixedBar/>
                      <CountryRegulation {...props}/>
                      <FooterPublic/>
                    </>
                  }/>

                <Route
                  path="/payment"
                  render={props => (
                    <>
                      <NavbarNew fixedBar/>
                      <Payment {...props}/>
                      <FooterPublic/>
                    </>
                  )}/>

                <Route
                  path="/:lang([A-Za-z]{2})?/:rest*"
                  render={props => {
                    // when we redirect with to a proper url the basename with proper lang code is triggered
                    // thus the lang prop becomes undefined... else keeps the any random url that is not matched above
                    if (props.match.params.lang && !availableLanguages.includes(props.match.params.lang)) {
                      return (<Redirect to={`/${props.match.params.rest}`}/>);
                    } else {
                      return (<>
                        <NavbarNew fixedBar/>
                        <NotFoundPage vals={props}/>
                        <FooterPublic/>
                      </>);
                    }
                  }}/>

                <Route
                  path="*"
                  render={props => (
                    <>
                      <NavbarNew fixedBar/>
                      <NotFoundPage vals={props}/>
                      <FooterPublic/>
                    </>
                  )}
                />

              </Switch>

              <CookieConsent location="bottom" buttonText={i18n.t("cookieBar.ok", "OK")}
                             containerClasses="-cookieContainer" buttonClasses="-cookieButton" expires={150}
                             style={{position: "sticky", background: "rgba(0, 0, 0, .7)"}}>
                <div className="-cookieText">
                  <Trans i18nKey="cookieBar.text">
                    We use cookies to personalise content and ads, to provide social media features and to analyse our
                    traffic. You consent to our cookies if you continue to use our website. You can find more about our
                    policies here:
                  </Trans>
                  {" "}
                  <a href={`${baseUrl()}/cookies-policy`}><Trans i18nKey="cookieBar.link">Cookies Policy</Trans></a>
                </div>
              </CookieConsent>

            </Suspense>
          </ErrorBoundary>

        </Router>
      </div>
    </HelmetProvider>
  );
}

// export default Sentry.withProfiler(App);
export default App;
