import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locales/en/translation.json";
import tr from "./locales/tr/translation.json";
import de from "./locales/de/translation.json";
import es from "./locales/es/translation.json";


const fallbackLng = ["en"];
const availableLanguages = ["en", "tr", "de", "es"];
const order = ["path", "localStorage", "htmlTag", "subdomain"]

const resources = {
  en: {
    translation: en,
  },
  tr: {
    translation: tr,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    ns: ["translation"],
    detection: {
      order: order,
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
    // backend: {
    //   loadPath: "./locales/{{lng}}/{{ns}}.json"
    // }
  });

export default i18n;
