import axios from "axios";
import * as moment from "moment-timezone";
import {acceptedCurrencies, dateTimeConvertFormat, dateTimeFormat, prefTags, sessionTime} from "./variables";
// import * as Sentry from "@sentry/react";
import {lazy} from "react";
import i18n from "i18next";


export const baseUrl = () => {
  return (i18n.language === "en" ? "" : "/" + i18n.language);
};

export const setFlightTimes = (theTime, theZone) => {
  let DTU = theTime || "";
  let DTUFmt = (theTime && theTime.format(dateTimeFormat)) || "";
  let DT = (theTime && moment.tz(theTime.format(dateTimeConvertFormat), dateTimeConvertFormat, theZone)) || "";
  let DTFmt = (DT && DT.toISOString()) || "";
  let DTLocalFmt = (DT && DT.format()) || "";
  return {
    DTU,
    DTUFmt,
    DT,
    DTFmt,
    DTLocalFmt,
  };
};

export const sortClinics = (clinics) => {
  let clinicsObj = clinics;
  if ("e-nabiz" in clinicsObj) delete clinicsObj["e-nabiz"];
  if ("eu-digital-covid-certificate" in clinicsObj) delete clinicsObj["eu-digital-covid-certificate"];
  let preferredClinics = {};
  Object.keys(clinicsObj)
        .filter(c => prefTags.some(k => clinicsObj[c].tags.includes(k)))
        .map(c => {
          preferredClinics[c] = clinicsObj[c];
          delete clinicsObj[c];
        });
  return {...preferredClinics, ...clinicsObj};
};

// fixing for 'SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this
// document.' SEE https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js

class cookieStore {
  constructor() {}

  getItem(key) {
    let b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }

  setItem(key, val) {
    document.cookie = `${key}=${val}`;
  }

  removeItem(key) {
    document.cookie = `${key}=; Max-Age=-99999999;`;
  }

  cookieTest() {
    try {
      this.setItem("tst", 777);
      let tst = this.getItem("tst");
      if (tst !== 777) throw "assert not equal";
      this.removeItem("tst");
      return true;
    } catch (e) {
      return false;
    }
  }
}


function localStorageTest() {
  try {
    localStorage.setItem("tst", 777);
    let tst = localStorage.getItem("tst");
    if (tst !== 777) throw "assert not equal";
    localStorage.removeItem("tst");
    return true;
  } catch (e) {
    return false;
  }
}

function sessionStorageTest() {
  try {
    sessionStorage.setItem("tst", 777);
    let tst = sessionStorage.getItem("tst");
    if (tst !== 777) throw "assert not equal";
    sessionStorage.removeItem("tst");
    return true;
  } catch (e) {
    return false;
  }
}

export const whichStore = () => {
  const cookieStorage = new cookieStore();
  if (localStorageTest()) {
    return localStorage;
  } else if (sessionStorageTest()) {
    return sessionStorage;
  } else if (cookieStorage.cookieTest()) {
    return cookieStorage;
  } else {
    return undefined;
  }
};

export const checkUrlLocale = () => {
  if (baseUrl() && !window.location.pathname.startsWith(baseUrl())) {
    window.location.replace(`${baseUrl()}${window.location.pathname}`);
  } else if (window.location.pathname.startsWith("/en")) {
    window.location.replace(window.location.pathname.replace("/en", "/").replace("//", "/"));
  }
};

export const checkParams = () => {
  let tsNow = +new Date();
  const browseStore = whichStore();
  if (browseStore) {
    let tsSession = parseInt(browseStore.getItem("sessionTime"));
    let cC = browseStore.getItem("countryCode");
    if (!cC || cC === "null" || cC === "undefined" || !tsSession || tsNow - tsSession > sessionTime) {
      browseStore.setItem("countryCode", "");
      browseStore.setItem("countryCurrency", "");
      browseStore.setItem("countryName", "");
      browseStore.setItem("countrySlug", "");
      browseStore.setItem("countryTimezone", "");
      browseStore.setItem("sessionTime", "");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get_params`)
        .then((resp) => {
          browseStore.setItem("countryCode", resp.data.countryCode);
          browseStore.setItem("countryCurrency", resp.data.countryCurrency);
          browseStore.setItem("countryName", resp.data.countryName);
          browseStore.setItem("countrySlug", resp.data.countrySlug);
          browseStore.setItem("countryTimezone", resp.data.countryTimezone);
          browseStore.setItem("sessionTime", +new Date());
        });
    }
  }
};

export const handle404 = (props, path, params) => {
  props.history.push({
    pathname: "/not-found",
    search: `?path=${path}`,
    state: {path: path, params: params},
  });
};

export const pageView = () => {
  window.dataLayer.push({
    event: "pageview",
    page: {
      url: window.location.origin + window.location.pathname + window.location.search,
    },
  });
};

export const getUserCurrency = () => {
  const browseStore = whichStore();
  let cC = browseStore ? browseStore.getItem("countryCurrency") : "";
  if (!cC || cC !== "null" || cC !== "undefined") {
    return cC;
  } else {
    return "";
  }
};

export const determineCurrencies = (extraCur = "", fallbackCur = "USD") => {
  // hierarchy is in the order
  let userCur = getUserCurrency();
  let userCurrency = (acceptedCurrencies.includes(userCur) && userCur) || false;
  extraCur = (acceptedCurrencies.includes(extraCur) && extraCur) || false;
  fallbackCur = ((fallbackCur === "USD" || acceptedCurrencies.includes(fallbackCur)) && fallbackCur) || "USD";

  let baseCurrency = userCurrency || extraCur || fallbackCur;
  let availableCurrencies = [];
  if (userCurrency) availableCurrencies.push(userCurrency);
  if (extraCur && extraCur !== userCurrency) availableCurrencies.push(extraCur);
  if (fallbackCur !== userCurrency && fallbackCur !== extraCur) availableCurrencies.push(fallbackCur);

  return {
    userCurrency,
    availableCurrencies,
    baseCurrency,
  };
};

export const getTestTypes = () => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/marketplace/test-types`)
              .then(resp => {
                return resp.data.data;
              })
              .catch((err) => {
                // Sentry.captureException(err, "/api/v1/marketplace/test-types");
                return {};
              });
};

// This function try to fix ChunkLoadError for lazy loading component
// SEE: https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb
export const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageFR = "pageForceRefd";
    const browseStore = whichStore();
    const pageAlreadyForced = browseStore ? JSON.parse(browseStore.getItem(pageFR) || "false") : false;
    try {
      const component = await componentImport();
      if (browseStore) browseStore.setItem(pageFR, "false");
      return component;
    } catch (error) {
      if (!pageAlreadyForced) {
        if (browseStore) browseStore.setItem(pageFR, "true");
        return window.location.reload();
      }
      throw error;
    }
  });
