import React from "react";
import axios from "axios";
// import * as Sentry from "@sentry/react";
import {Trans, withTranslation} from "react-i18next";

import FooterPublic from "../headtail/Footers/FooterPublic";
import NavbarSub from "../headtail/Navbars/NavbarSub";
import SEO from "../seo/SEO";
import ErrorBody from "./ErrorBody";


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {error: null, errorInfo: null};
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error: error, errorInfo: errorInfo});
    if (process.env.NODE_ENV === "production") {
      let postVals = {
        status_code: 503,
        log_type: "error-boundary",
        url: (window.location.pathname + window.location.search) || "N/A",
        params: JSON.stringify({
          error: error.toString(),
          errorInfo: errorInfo.componentStack,
          referrer: document.referrer,
        }),
      };
      axios
        .post(`/api/v1/response-log/`, postVals, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then(resp => {
          // console.log(resp)
        })
        .catch((err) => {
          // Sentry.captureException(err, "/response-log/");
          return {};
        });
      // FIXME this is temp solution not to call Sentry for v1 errors
      // if (!postVals.url.includes('flightplan')) Sentry.captureException(error, errorInfo);
    }
  }

  render() {
    const {t} = this.props;

    if (this.state.errorInfo) {
      return (
        <>
          <SEO title={t("errorBoundary.seoTitle", "503 Error on Page - FurtherPass")}/>
          <NavbarSub/>
          <ErrorBody
            errorNumber={<Trans i18nKey="errorBoundary.error">503</Trans>}
            errorDescription={<Trans i18nKey="errorBoundary.description">
              Sorry you have experienced an unexpected error.
            </Trans>}
            errorDetail={<Trans i18nKey="errorBoundary.detail">
              The error has been reported.
            </Trans>}
          />
          {process.env.NODE_ENV === "development" ?
            <div className="container-fluid">
              Error: {JSON.stringify(this.state.error)}<br/>
              <br/>
              ErrorInfo: {JSON.stringify(this.state.errorInfo)}
            </div>
            : null}
          <FooterPublic/></>
      );
    }
    return this.props.children;
  }
}


export default withTranslation()(ErrorBoundary);
