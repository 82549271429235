import React from "react";
import {Trans, withTranslation} from "react-i18next";

import LanguageChanger from "../../../forms/language/LanguageChanger";
import {baseUrl} from "../../../utils/functions";


function NavbarItems() {

  const getActive = (url) => window.location.pathname.includes(url.replace("clinics", "clinic"));

  const links = {
    "clinics":
      <Trans i18nKey="navbar.clinics">Clinics Near You</Trans>,
    "travel-restrictions-and-travel-bans":
      <Trans i18nKey="navbar.restrictions">Travel Restrictions</Trans>,
    // "download":
    //   <Trans i18nKey="navbar.download">Download App</Trans>,
    "https://blog.furtherpass.com/blog/travel-insights-from-furtherpass-blog-1":
      <Trans i18nKey="navbar.blog">Blog</Trans>,
    "https://blog.furtherpass.com/blog/travel-regulation-update-2":
      <Trans i18nKey="navbar.regulations">Travel Regulations</Trans>,
    // "contact-us":
    //   <Trans i18nKey="navbar.contact">Contact Us</Trans>,
  };

  return (
    <ul className="navbar-nav">
      {Object.keys(links).map(item => {
        const url = item.startsWith("https") ? item : baseUrl() + "/" + item;
        const active = getActive(url);
        return (
          <li className="nav-item" key={item}>
            <a className={"nav-link" + (active ? " active" : "")}
               {...(active ? {"aria-current": "page"} : {})}
               href={url}>{links[item]}</a>
          </li>
        );
      })}
      <li className="nav-item">
        <LanguageChanger/>
      </li>
    </ul>
  );
}


export default withTranslation()(NavbarItems);
