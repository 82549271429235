import React, {Component} from "react";
import {Trans, withTranslation} from "react-i18next";


class DisclaimerGeneral extends Component {
  render() {
    return (
      <p className="-textDisclaimer">
        <Trans i18nKey="disclaimergeneral">
          We do our best to ensure that all information presented by us is complete, accurate and up to date.
          Nevertheless, We strongly advise you to check the regulation of destination country with authorities or your
          airline before you travel. It is travelers responsibility to check regulations and we cannot guarantee the
          reliability of the information on our website, including information about the coronavirus and the available
          tests. All information provided by us about the coronavirus, the different types of tests and related topics
          is based solely on reporting from official bodies.
        </Trans>
      </p>
    );
  }
}


export default withTranslation()(DisclaimerGeneral);
