import {createTheme} from "@welcome-ui/core";
import React from "react";
import {Trans} from "react-i18next";


export const sessionTimeMin = 15;  // minutes
export const sessionTime = sessionTimeMin * 60 * 1000;  // milliseconds

export const dateTimeFormat = "DD MMM YYYY, HH:mm";
export const dateTimeConvertFormat = "YYYY-MM-DD HH:mm";
export const dateFormat = "DD MMM YYYY";
export const dateFormatShort = "DD MMM, HH:mm";
export const dateConvertFormat = "YYYY-MM-DD";
export const dateFormatField = "dd MMM yyyy";
export const timeFormatField = "HH:mm";
export const dayOrder = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export const availableLanguages = ["en", "tr", "de", "es"];

// TODO get default variables from backend on build
export const acceptedCurrencies = ["GBP", "TRY", "USD", "ILS", "PKR", "RUB", "EUR"];

export const pickerTheme = createTheme({
  colors: {
    primary: {
      500: "#6466AC",
    },
    secondary: {
      500: "#00ffac",
    },
  },
});

export const serviceTypes = {
  "testing_at_clinic": <Trans i18nKey="utils.service.clinic">Testing at Clinic</Trans>,
  "home_service": <Trans i18nKey="utils.service.home">Home Service</Trans>,
  "postal_service": <Trans i18nKey="utils.service.postal">Postal Service</Trans>,
  "drive_through": <Trans i18nKey="utils.service.drive">Drive Through</Trans>,
  "airport_service": <Trans i18nKey="utils.service.airport">Airport Service</Trans>,
};

export const paymentOptions = {
  "pay_online": <Trans i18nKey="payment.pay_online">Pay Online with Credit Card</Trans>,
  "pay_at_clinic": <Trans i18nKey="payment.pay_online">Pay at the Clinic</Trans>,
  "pay_partial": <Trans i18nKey="payment.pay_online">Pay Booking Fee</Trans>,
};

export const prefTags = ["preferred", "fp", "online_book"];

export const refConditions = {
  "before_departure": <Trans i18nKey="utils.bd">Before Departure</Trans>,
  "before_arrival": <Trans i18nKey="utils.ba">Before Arrival</Trans>,
  "test_result_time": <Trans i18nKey="utils.trt">Test Result Time</Trans>,
  "sample_taken_time": <Trans i18nKey="utils.stt">Sample Taken Time</Trans>,
}
