import React from "react";


export const AppleLink = (props) => {
  return (
    <a target="_blank" rel="noopener noreferrer"
       href="https://apps.apple.com/app/furtherpass/id1582819895">
      <span className="fi-store-apple badges"/>
    </a>
  )
}

export const GoogleLink = (props) => {
  return (
    <a target="_blank" rel="noopener noreferrer"
       href="https://play.google.com/store/apps/details?id=network.further.furtherpass.prod">
      <span className="fi-store-google badges"/>
    </a>
  )
}

export const StoreLinks = (props) => {
  return (
    <div className="-storeLinks">
      <GoogleLink/>
      <AppleLink/>
    </div>
  )
}
