import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import {availableLanguages} from "../../utils/variables";


const LanguageChanger = () => {
  const {i18n} = useTranslation();
  const [lang, setLang] = useState(i18n.language || "en");

  let handleChange = (code) => {
    if (lang !== code) {
      let newUrl;
      if (lang === "en") {
        newUrl = window.location.pathname.replace(`/`, `/${code}/`);
      } else if (code === "en") {
        newUrl = window.location.pathname.replace(`/${lang}`, ``);
      } else {
        newUrl = window.location.pathname.replace(`/${lang}`, `/${code}`);
      }
      window.history.pushState("", "", newUrl);
      setLang(code);
      i18n.changeLanguage(code);
    }
  };

  return (
    <div className="-langSelector">
      {availableLanguages.map(code => {
        return (
          <a className={"nav-link" + (lang === code ? " -active" : "")} key={code}
             onClick={() => handleChange(code)}>
            {code.toUpperCase()}
          </a>
        );
      })}
    </div>
  );
};

export default LanguageChanger;
