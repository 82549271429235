import logoIcon from "../../../assets/img/logo-icon.svg";


const Loading = () => {
  return (
    <div className="overlay">
      <div className="text-center">
        <img src={logoIcon} alt="FurtherPass"/>
      </div>
    </div>
  );
};


export default Loading;
