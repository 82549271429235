import React, {Component} from "react";
import Obfuscate from "react-obfuscate";
import {Trans, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {StoreLinks} from "../../info/StoreLinks";

import DisclaimerGeneral from "../../legal/DisclaimerGeneral";
import StarAllianceLogo from "../../../../assets/img/star-alliance-logo.svg";


class FooterPublic extends Component {
  render() {
    const {t} = this.props;

    return (
      <div className="container-fluid -footer">
        <div className="container">
          <div className="row">
            <div className="col-md-3 -logoContainer">
              <span className="fi-logo-horizontal -footerLogo"/>
            </div>
            <div className="col-md-9">
              <StoreLinks/>
            </div>

            <div className="col-md-6 -storeLinks offset-md-3">
              <p>
                <Trans i18nKey="footer.apptext">
                  All your Travel Documents at your fingertips with
                  <br/>
                  FurtherPass Application.
                </Trans>
              </p>
            </div>

          </div>
          <div className="row">
            <div className="col-md-9 offset-md-3 -contactInfo">
              <p>
                <Obfuscate style={{display: "inline-block"}} email="furtherpass@further.network"/>
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-9  offset-md-3">
              <br/>
              <Trans i18nKey="footer.trustedby">Trusted by</Trans><br/>
              <img className="StarAllianceLogo" src={StarAllianceLogo}
                   alt={t("footer.staralttext", "FurtherPass is trusted by Star Alliance")}/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 offset-md-3 -disclaimer">
              <Link to="/terms-of-use" target="_blank">
                <Trans i18nKey="footer.termsofuse">Terms of Use</Trans>
              </Link>
              <Link to="/privacy-policy" target="_blank">
                <Trans i18nKey="footer.privacypolicy">Privacy Policy</Trans>
              </Link>
              <Link to="/legal-notice" target="_blank">
                <Trans i18nKey="footer.legalnotice">Legal Notice</Trans>
              </Link>
              <Link to="/cancellation-policy" target="_blank">
                <Trans i18nKey="footer.cancellation">Cancellation Policy</Trans>
              </Link>
              <Link to="/cookies-policy" target="_blank">
                <Trans i18nKey="footer.cookies">Cookies Policy</Trans>
              </Link>
              <DisclaimerGeneral/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 offset-md-3 -disclaimer">
              <p className="-address">
                {/*<Trans i18nKey="footer.year"></Trans>*/}
                &copy; 2020-2023 Further Network<br/>
                <Trans i18nKey="footer.name">Further Corporation Limited</Trans>
                <br/>
                {/*<Trans i18nKey="footer.address"></Trans>*/}
                4th floor, 40 Gracechurch Street, London, EC3V 0BT, United Kingdom
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 -footerFurtherLogoContainer">
              <a href="https://further.network" target="_blank" rel="noreferrer noopener">
                <span className="fi-logo-further -footerFurtherLogo"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withTranslation()(FooterPublic);
