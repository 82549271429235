import React from "react";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";

import Cogs from "./Cogs";
import "./style.scss";


const ErrorBody = (props) => (
  <div className="container-fluid -errorPage">
    <div className="container errorBoundary">
      <div className="outer">
        <div className="middle">
          <div className="inner">
            <div className="inner-circle">
              <span className="bubble"><Cogs/></span>
              <span>{props.errorNumber}</span>
            </div>
            <span className="inner-status">{props.errorDescription}</span>
            <span className="inner-detail">{props.errorDetail}</span>
          </div>
          <div className="bottom">
            <Link to="/" className="btn btn-primary m-3">
              <Trans i18nKey="errorBody.home">Back To Home</Trans>
            </Link>
            <Link to="/clinics" className="btn btn-primary m-3">
              <Trans i18nKey="errorBody.clinics">Find Test Centers Near You</Trans>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ErrorBody;
