import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {baseUrl} from "../../../utils/functions";

import NavbarItems from "./NavbarItems";


class NavbarSub extends Component {

  render() {
    return (
      <>
        <div className="container-fluid -navbarSub">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-dark">
              <a className="navbar-brand" href={`${baseUrl()}/`}>
                <span className="fi-logo-horizontal -navbarSubLogo"/>
              </a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                      data-bs-target="#navbarNavAltMarkup"
                      aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"/>
              </button>
              <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                <NavbarItems/>
              </div>
            </nav>
          </div>
        </div>
      </>
    );
  }
}


export default withTranslation()(NavbarSub);
