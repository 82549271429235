import React from "react";
import {Helmet} from "react-helmet";
// import i18n from "i18next";
import {useTranslation} from "react-i18next";

import {availableLanguages} from "../../utils/variables";


// TODO language based SEO and move icons to here
const SEO = ({title, description, keywords, image, url, TwitterUrl, summary, noIndex = false}) => {

  const {t, i18n} = useTranslation();
  const defaultTitle = title || t("seo.title", "FurtherPass Travel Restrictions & PCR Test Centers for Travellers");
  const metaDescription = description || t("seo.description", "Find out travel restrictions for your destination and find the nearest pcr or antigen test centers quickly and book online easily.");
  const metaKeywords = keywords || null;
  const curUrl = url || window.location.pathname;
  const enUrl = i18n.language === "en" ?
    curUrl.replace(/\/$/g, "") :
    curUrl.replace(`/${i18n.language}`, "").replace(/\/$/g, "");
  const metaTwitterUrl = TwitterUrl || null;
  // const metaSummary = summary || "";


  return (
    <Helmet>
      <html lang={i18n.language}/>
      <title>{defaultTitle}</title>
      <meta name="description" content={metaDescription}/>

      {keywords ?
        <meta name="keywords" content={metaKeywords}/>
        : null
      }

      {noIndex ?
        <meta name="robots" content="noindex"/>
        :
        <meta name="robots" content="INDEX,FOLLOW,MAX-SNIPPET:-1, MAX-VIDEO-PREVIEW:-1, MAX-IMAGE-PREVIEW:LARGE"/>
      }

      <meta property="og:image" content={image}/>
      <meta property="og:url" content={curUrl}/>
      <meta property="og:title" content={defaultTitle}/>
      <meta name="og:description" content={metaDescription}/>

      {/*<link rel="alternate" hrefLang="x-default" href={`${window.location.origin}${enUrl}`}/>*/}
      {availableLanguages.map(code => {
        if (curUrl.includes("terms-of-use") || curUrl.includes("privacy-policy")) return null;
        return <link key={code} rel="alternate" hrefLang={code}
                     href={code === "en" ?
                       `${window.location.origin}${enUrl}` :
                       `${window.location.origin}${enUrl.replace(`/`, `/${code}/`)
                                                        .replace(/\/$/g, "")}`}
        />;
      })}
      <link rel="alternate" hrefLang="x-default" href={`${window.location.origin}${enUrl}`}/>
      <link rel="canonical" href={`${window.location.origin}${curUrl}`}/>

      <meta name="twitter:card" content={summary}/>
      <meta property="og:url" content={metaTwitterUrl}/>
      <meta property="og:title" content={defaultTitle}/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:image" content={image}/>

      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta charSet="utf-8"/>
    </Helmet>
  );
};


export default SEO;
